import React from 'react'
import styles from '../styles/components/section-title.module.scss'
import Reveal from 'react-reveal/Reveal'

import classNames from 'classnames/bind'
let cx = classNames.bind(styles)

const SectionTitle = ({
  title,
  titleColor,
  titleSize,
  label,
  labelColor,
  center,
  style,
}) => {
  return (
    <>
      {label && (
        <Reveal effect="fadeInUpShorter">
          <div
            className={cx(
              'label',
              { center },
              { red: labelColor === 'red' },
              { white: labelColor === 'white' }
            )}
          >
            {label}
          </div>
        </Reveal>
      )}
      <Reveal effect="fadeInUpShorter" duration={1500}>
        <h2
          className={cx(
            'sectionTitle',
            { center },
            { red: titleColor === 'red' },
            { white: titleColor === 'white' },
            { darkBlue: titleColor === 'dark-blue' },
            { small: titleSize === 'small' }
          )}
          style={style}
        >
          {title}
        </h2>
      </Reveal>
    </>
  )
}

export default SectionTitle
