import React from 'react'

import styles from '../styles/components/standard-padding.module.scss'

import classNames from 'classnames/bind'

const StandardPadding = ({ children, style, className }) => {
  return (
    <div className={classNames(styles.padding, className)} style={style}>
      {children}
    </div>
  )
}

export default StandardPadding
