import React from 'react'
import Section from '../sections/Section'
import StandardPadding from '../components/standardPadding'
import Reveal from 'react-reveal/Reveal'

import styles from '../styles/sections/list-section.module.scss'

import classNames from 'classnames/bind'
let cx = classNames.bind(styles)

const List = ({ title, items }) => {
  return (
    <Reveal effect="fadeInUpShorter">
      <div className={styles.list}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{title}</h3>
        </div>
        <ul>
          {items.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </div>
    </Reveal>
  )
}

const ListSection = ({ list1, list2 }) => {
  return (
    <Section className={styles.listSection}>
      <div className={styles.background} />
      <StandardPadding>
        <div className={styles.listContainer}>
          <List title={list1.title} items={list1.items} />
          <List title={list2.title} items={list2.items} />
        </div>
      </StandardPadding>
    </Section>
  )
}

export default ListSection
