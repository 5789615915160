import React from 'react'
import Img from 'gatsby-image'
import SectionTitle from '../components/sectionTitle'
import StandardPadding from '../components/standardPadding'
import Section from '../sections/Section'

import styles from '../styles/components/logo-grid.module.scss'

import classNames from 'classnames/bind'
let cx = classNames.bind(styles)

const LogoGrid = ({ sectionTitle, logos, center, titleSize }) => {
  return (
    <Section>
      <StandardPadding>
        <SectionTitle
          title={sectionTitle}
          titleColor="dark-blue"
          center={center}
          titleSize={titleSize}
        />
      </StandardPadding>
      <StandardPadding style={{ padding: 0 }}>
        <div className={styles.grid}>
          {logos.map((logo, i) => {
            return (
              <div className={styles.logoWrapper}>
                {logo.link && (
                  <a href={logo.link} target="_blank" rel="noreferrer">
                    <Img fluid={logo.image.fluid} />
                  </a>
                )}
                {!logo.link && <Img fluid={logo.image.fluid} />}
              </div>
            )
          })}
          {logos.length % 3 !== 0 && <div className={styles.logoWrapper} />}
        </div>
      </StandardPadding>
    </Section>
  )
}

export default LogoGrid
