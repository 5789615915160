import React from 'react'

import Section from './Section'
import SectionTitle from '../components/sectionTitle'
import StandardPadding from '../components/standardPadding'
import Reveal from 'react-reveal/Reveal'

import styles from '../styles/sections/highlighted-text-section.module.scss'

const HighlightedTextSection = ({ title, label, paragraph, spacing }) => {
  return (
    <Section spacing={spacing}>
      <StandardPadding className={styles.textWrapper}>
        <div className={styles.background} />
        <SectionTitle
          title={title}
          label={label}
          titleColor="red"
          labelColor="white"
        />
        <Reveal effect="fadeInUpShorter">
          <div className={styles.paragraphContainer}>
            <p>{paragraph}</p>
          </div>
        </Reveal>
      </StandardPadding>
    </Section>
  )
}

export default HighlightedTextSection
