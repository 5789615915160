import React from 'react'
import styles from '../styles/components/page-wrapper.module.scss'

/*
 * Adds vertical padding to make room for the header
 */
const PageWrapper = ({ children }) => {
  return <div className={styles.container}>{children}</div>
}

export default PageWrapper
