import React from 'react'
import styles from '../styles/sections/section.module.scss'
import classNames from 'classnames/bind'

const Section = ({ children, className, style, spacing, background, last }) => {
  return (
    <section
      className={classNames(
        styles.section,
        { [styles.small]: spacing === 'small' },
        { [styles.large]: spacing === 'large' },
        { [styles.noSpacing]: spacing === 'none' },
        { [styles.background]: background },
        { [styles.peach]: background === 'peach' },
        { [styles.last]: last },
        className
      )}
      style={style}
    >
      {children}
    </section>
  )
}

export default Section
