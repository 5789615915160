import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Reveal from 'react-reveal/Reveal'

import Layout from '../components/layout'
import SEO from '../components/SEO'
import PageWrapper from '../components/pageWrapper'
import StandardPadding from '../components/standardPadding'
import RedButton from '../components/redButton'
import LogoGrid from '../components/logoGrid'
import SectionTitle from '../components/sectionTitle'

import Section from '../sections/Section'
import ListSection from '../sections/ListSection'
import NumberedSection from '../sections/NumberedSection'
import TextSection from '../sections/TextSection'
import HighlightedTextSection from '../sections/HighlightedTextSection'

import styles from '../styles/pages/partnership-program.module.scss'

import tree from '../images/icons/tree.png'
import integrate from '../images/icons/integrate.png'
import dot from '../images/icons/dot.png'

const PartnershipProgram = ({ data }) => {
  return (
    <Layout>
      <SEO
        customTitle="Partnership Program"
        customDescription="Mentorship, transformational workshops and an array of services
                to help your startup adapt and grow."
        pagePath="partnership-program"
      />
      <PageWrapper>
        <section className={styles.hero}>
          <Img
            fluid={data.background.childImageSharp.fluid}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
            }}
          />
          <StandardPadding style={{ zIndex: '1' }}>
            <Reveal effect="fadeInUp">
              <p className={styles.label}>15 Finches Partnership Program</p>
            </Reveal>
            <Reveal effect="fadeInUp" duration={1500}>
              <h1>
                Mentorship, transformational workshops and an array of services
                to help your startup adapt and grow.
              </h1>
            </Reveal>
            <Reveal effect="fadeInUp" duration={2000}>
              <RedButton text="Apply Now" to="/partnership-application/" />
            </Reveal>
          </StandardPadding>
        </section>
        <TextSection
          spacing="small"
          title="The Program"
          paragraph="Offering the best early-stage startups access to mentorship,
                transformational workshops and an array of creative, development
                and product services during the three month program. Accepted
                startups will receive a unique program schedule as well as
                networking and pitch opportunities with founders and VCs."
          info="Rolling Admission - Apply anytime"
        />
        <ListSection
          list1={{
            title: 'What the program entails',
            items: [
              '3 month timeline',
              'Curated program for your startup’s unique needs',
              'Mentorship from experts and founders',
              'Intensive stress testing, refining pitch and improving product workshops',
              'Full service creative, development and product team at your disposal',
              'Networking and pitch opportunities with founders and VCs',
              '1-5% equity in your company, operating expenses charged for creative, dev, product team',
            ],
          }}
          list2={{
            title: 'Services provided',
            items: [
              'Brand Strategy & Identity',
              'Website Design & Development',
              'UI/UX Design',
              'Marketing Strategy & Planning',
              'Marketing Materials & Activation',
              'Product Strategy',
              'Product Engineering (MVP and beyond)',
            ],
          }}
        />
        <NumberedSection
          title="What do our partnership program  startups receive?"
          label="15 Finches Partnership Program"
          items={[
            {
              subtext:
                'Curated program and mentorship to help reach the next growth stage',
              icon: tree,
            },
            {
              subtext:
                'Fully integrated startup-focused creative agency to help execute on objectives',
              icon: integrate,
            },
            {
              subtext:
                'Networking and pitch opportunities with industry experts, founders and VCs',
              icon: dot,
            },
          ]}
        />
        <HighlightedTextSection
          title="Curated for Your Startup’s Needs"
          label="Built for You"
          paragraph="Every startup is different. We've found that the best path to helping startups succeed is not to force them into a specific program model with predefined equity, schedule and mentors. Instead, we like to build the program around the startup. If accepted into the accelerator program, your startup will receive a unique schedule that will contain specific sprints, defined services over time and meetings with hand-selected mentors, founders and VCs that relate to your product and industry. The amount of equity and compensation that we'll recommend will relate directly to your needs, stage and valuation. The goal is to help minimize your burn rate and maximize your opportunities for growth and fundraising."
          spacing="large"
        />
        <TextSection
          title="Technology-enabled B2B and B2C focus"
          label="Where we focus"
          paragraph="We're primarily focused on working with web-based digital products, both B2B and B2C. These may include SaaS platforms, D2C businesses or any tech-enabled businesses. If your startup doesn't fit within these categories, don't worry! We still consider strong applicants who fall outside of our primary selection criteria."
          spacing="large"
        />
        <TextSection
          title="Founder, Startup and VC Community"
          titleColor="red"
          label="Our Community"
          paragraph="One of the most important aspects of our partnership program is the community of founders, experts and venture capital groups within our network. You'll have access to people who have done it before and who want to help. At the end of the accelerator program we'll make introductions to VCs who may be interested in hearing your startup pitch."
          spacing="large"
          background="peach"
          center
        />
        <LogoGrid
          {...data.contentfulPage.sections[0]}
          center
          titleSize="small"
        />
        <Section last background="peach">
          <StandardPadding>
            <SectionTitle
              title="Let’s get building!"
              titleColor="dark-blue"
              label="15 Finches Partnership Program"
              center
            />
            <Reveal effect="fadeInUpShorter">
              <RedButton
                text="Apply Now"
                style={{ margin: '48px auto 0' }}
                to="/partnership-application/"
              />
            </Reveal>
          </StandardPadding>
        </Section>
      </PageWrapper>
    </Layout>
  )
}

export default PartnershipProgram

export const PartnershipProgramPageQuery = graphql`
  query {
    background: file(relativePath: { eq: "accelerator-background.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contentfulPage(slug: { eq: "partnership-program" }) {
      sections {
        ... on ContentfulImageGrid {
          sectionTitle
          logos {
            image {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            link
          }
        }
      }
    }
  }
`
